<template>
  <header id="header" class="fixed-top border-bottom bg-main shadow-sm">
    <div class="container-fluid shadow-sm">
      <nav
        class="
          navbar navbar-expand-md navbar-dark
          px-0
          pr-sm-3
          navbar-offcanvas
        "
      >
        <a
          @click="back"
          class="btn p-0 mr-md-4 text-light h5 mb-0"
        >
          <i class="fas fa-arrow-left"></i>
        </a>
        <span
          class="
            navbar-brand
            bg-transparent
            rounded
            p-0
            text-center
            mr-0 mr-md-5
          "
        >
          <span class="h6 font-weight-bold text-truncate">Grup Pelanggan</span>
        </span>
        <Menu>
          <ul class="navbar-nav w-100 pl-3 pl-md-0">
            <li class="nav-item mt-1 mb-n1">
              <router-link
                to="/jual/pelanggan"
                class="nav-link"
                title="Pelanggan"
                >Pelanggan</router-link
              >
            </li>
            <li class="nav-item mt-1 mb-n1">
              <router-link
                to="/jual/pelanggan/group"
                class="nav-link"
                title="Grup Pelanggan"
                >Grup Pelanggan</router-link
              >
            </li>
            <li class="nav-item mt-1 mb-n1">
              <router-link
                to="/jual/pelanggan/reseller"
                class="nav-link"
                title="Reseller"
                >Reseller</router-link
              >
            </li>
          </ul>
        </Menu>
      </nav>
    </div>
    <!-- .container -->
  </header>

  <div class="container-fluid mt-header">
    <div class="row" id="body-sidemenu">
      <!-- MAIN -->
      <div id="main-content" class="col with-fixed-sidebar">
        <div class="row horizontal-menu-100 mb-3">
          <div class="col-12 px-0">
            <div class="list-group list-group-horizontal text-center">
              <router-link to="/jual/pelanggan" class="list-group-item flex-fill">PELANGGAN</router-link>
              <a href="javascript:void(0);" class="list-group-item flex-fill on">GRUP</a>
              <!-- <a href="./jual-reseller.html" class="list-group-item flex-fill">RESELLER</a> -->
            </div>
          </div> <!-- .col -->
        </div> <!-- .row -->

        <form @submit.prevent="searchData">
          <div class="input-group input-group-sm my-3">
            <div class="input-group mb-3">
              <input
                type="text"
                class="form-control"
                placeholder="Cari..."
                v-model="state.search"
              />
              <span class="input-group-append">
                <button type="submit" class="btn btn-outline-main">
                  <i class="fa fa-search"></i>
                </button>
              </span>
            </div>
          </div>
        </form>

        <div id="resultList" class="row mt-3 mb-5" v-if="state.availlable">
          <div
            class="col-12 col-md-6 col-lg-4 col-xl-3"
            v-for="dt in state.data"
            :key="dt.id"
          >
            <div class="card mb-2">
              <div class="card-body p-0">
                <router-link :to="'/jual/pelanggan/group/edit/' + dt.id">
                  <div class="float-left w-25 text-center mt-3">
                    <i class="fas fa-users" style="font-size: 30px"></i>
                  </div>
                  <div class="float-left w-50 py-2 pl-0">
                    <span
                      class="
                        card-title
                        font-weight-bold
                        d-block
                        mb-1
                        mt-1
                        text-truncate
                      "
                      >{{ dt.name }}</span
                    >
                    <p class="card-text mb-1 text-truncate">
                      {{ dt.desc == null ? "-" : dt.desc }}
                    </p>
                  </div>
                  <div class="float-right w-25 text-center mt-2">
                    <i class="fas fa-pencil-alt" style="font-size: 20px"></i>
                  </div>
                  <div class="clearfix"></div>
                </router-link>
              </div>
            </div>
          </div>
          <!-- .col -->
        </div>
        <div v-else class="col-12 col-md-6 col-lg-4 col-xl-3 mt-5">
          <h6 class="text-center">Data Tidak Ditemukan!</h6>
        </div>
        <!-- .row -->

        <router-link
          to="/jual/pelanggan/group/tambah"
          id="btn-float"
          class="
            bg-warning
            position-fixed
            rounded-circle
            shadow
            text-dark text-center
          "
        >
          <div class="d-flex justify-content-center mt-3">
            <i class="fas fa-plus" style="font-size: 25px"></i>
          </div>
        </router-link>
      </div>
      <!-- main-content -->
    </div>
    <!-- .row -->
  </div>
  <!-- .container -->
</template>
<script>
import { reactive, onMounted } from "vue";
import axios from "axios";
import Menu from "../../../components/menu/Menu.vue";

export default {
  setup() {
    const state = reactive({
      data: {},
      availlable: false,
      search: "",
    });

    const loadData = async () => {
      await axios.get("/api/seller/pelanggan-group").then((res) => {
        if (res.data.success) {
          state.data = res.data.data;
          if (state.data.length > 0) {
            state.availlable = true;
          } else {
            state.availlable = false;
          }
        }
      });
    };
    onMounted(() => {
      loadData();
    });
    const searchData = () => {
      if (state.search) {
        axios
          .get("/api/seller/pelanggan-group/search?search=" + state.search)
          .then((res) => {
            if (res.data.success) {
              if (res.data.data.length > 0) {
                state.data = res.data.data;
                state.availlable = true;
              } else {
                state.data = {};
                state.availlable = false;
              }
            }
          });
      }
    };
    const back = () => {
      window.history.back();
    }
    return {
      state,
      searchData,
      back,
    };
  },
  components: {
    Menu,
  },
};
</script>